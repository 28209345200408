.progressMainWrapper {
    background-color: transparent;
    height: 10px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10000000;
    width: 100%;
}

.progressMainStyle {
    height: 10px;
    background-color: #7c5c51a5;
    width: 15%;
    transition: width 0.2s ease-out;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}