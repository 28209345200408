body {
  background-color: rgb(235, 235, 235);
}

/* font-family: 'Poppins', sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Ubuntu', sans-serif; */
.title {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: large;
}

.active {
  color: #7a4700;
  font-weight: bold;
}

.floatingIn {
  opacity: 0;
  transform: translateY(50px);
  animation: floatIn 0.5s ease forwards;
}

@keyframes floatIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}